/**
 *
 * @param email string
 */
export const validateEmail = email => {
	const regex = /\S+@\S+\.\S+/
	return regex.test(email)
}

/**
 *
 * @param value string
 */
export function validateEmpty(value) {
	if (value.trim() === '') {
		return false
	}
	return true
}
